import React from "react"
import { InputBase, FormControl, InputLabel, Box } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  rootStyle: {
    width: "100%",
  },
  labelStyle: {
    color: "#0A3133",
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
      width: "100%",
      color: "black",
    },
  },
  input: {
    border: 0,
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FFFFFF",
    outline: "none",
    boxShadow: "none",
    border: props =>
      props.error ? "1.5px solid #FD6E84 " : "1.5px solid white",
    fontSize: 16,
    marginTop: "12px",
    marginBottom: "10px",
    padding: "10px 12px",
    marginRight: "10px",
    margin: "10px",
    marginLeft: 4,
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    fontFamily: ["DM Sans"].join(","),
  },
}))(InputBase)

function FormTextField({
  className,
  containerStyle,
  disabled,
  icon,
  inputStyle,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  type,
  value,
  errors,
  label,
}) {
  const classes = useStyles()

  return (
    <Box>
      <FormControl className={classes.rootStyle} style={containerStyle}>
        <InputLabel
          shrink
          className={classes.labelStyle}
          style={{ color: "#0A3133", marginBottom: "10px" }}
        >
          {label}
        </InputLabel>
        <BootstrapInput
          id="bootstrap-input"
          className={className}
          error={errors}
          disabled={disabled}
          icon={icon}
          inputStyle={inputStyle}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          type={type}
          value={value}
        />
      </FormControl>
    </Box>
  )
}

export default FormTextField
