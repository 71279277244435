import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import FormTextField from "../../../FormTextField"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "../../../../../redux/slices/clubSlice"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transprent",
    width:"calc(100% - 40px)",
    borderRadius: "10px 10px 0px 0px",
    height: "100%",
    paddingTop: "10px",
    paddingLeft: "40px",
    fontFamily: "DM Sans",
  },
  titleText: {
    fontSize: "28px",
    marginBottom: "40px",
    fontWeight: "400",
    fontStyle: "normal",
  },
  errorText: {
    color: "#FD6E84",
    fontSize: "12px",
    margin: "0px 0px 10px 10px",
  },
}))

function BillingForm({
  errors,
  setFormsErrors,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const state = useSelector(state => state.clubReducer.billingInfo)

  const handleChange = (key, value) => {
    let temp = { ...errors.billingInfo }

    delete temp[key]

    setFormsErrors({ ...errors, billingInfo: temp })
    dispatch(actions.clubInfo({ key1: "billingInfo", key2: key, value }))
  }

  const handlePasswordChange = e => {
    setNewPassword(e.target.value)
  }

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
  }

  return (
    <div className={classes.root}>
      <div>
        <Container style={{ marginLeft: "0px" }} maxWidth="lg">
          <Typography className={classes.titleText}>3. Log in</Typography>
          <div style={{ width: "540px" }}>
            <FormTextField
              errors={errors["billingInfo"]?.name}
              onChange={e => handleChange("name", e.target.value)}
              placeHolder="Your Name"
              label="Your Name"
              value={state.name}
            />
            {errors["billingInfo"]?.name && (
              <Typography className={classes.errorText}>
                Name cannot be empty
              </Typography>
            )}

            <FormTextField
              errors={errors["billingInfo"]?.email}
              onChange={e => handleChange("email", e.target.value)}
              placeHolder="Your Email"
              label="Your Email"
              value={state.email}
            />
            {errors["billingInfo"]?.email && (
              <Typography className={classes.errorText}>
                {errors["billingInfo"]?.email === "Required*"
                  ? "Email cannot be empty"
                  : "Enter a valid email address."}
              </Typography>
            )}

            <FormTextField
              errors={errors["billingInfo"]?.password}
              onChange={handlePasswordChange}
              placeHolder="Password"
              label="Password"
              value={newPassword}
              type="password"
            />
            {errors["billingInfo"]?.password && (
              <Typography className={classes.errorText}>
                {errors["billingInfo"]?.password}
              </Typography>
            )}
            <FormTextField
              errors={errors["billingInfo"]?.confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeHolder="Re-Enter Password"
              label="Re-Enter Password"
              value={confirmPassword}
              type="password"
            />
            {errors["billingInfo"]?.confirmPassword && (
              <Typography className={classes.errorText}>
                {errors["billingInfo"]?.confirmPassword}
              </Typography>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default BillingForm
